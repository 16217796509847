import { FluentTriangleDown12Filled } from '@/assets/icons/comm/FluentTriangleDown12Filled';
import PullToRefresh from '@/components/base/basePullToRefresh';
import BaseTopNav from '@/components/base/baseTopNav';
import { CustomerServiceTypeEnum, RechargeTypeEnum } from '@/enums/businessEnum';
import { usePaginationPage } from '@/hooks/usePaginationPage';
import { getC2CListApi, getFiatCurrencyBalanceApi, getRechargeTypeApi } from '@/services/api/requestApi';
import { useReq } from '@/services/net/request';
import { useDebounceFn, useMount, useUpdateEffect } from 'ahooks';
import {  useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AmountFilterModal from './components/AmountFilterModal';
import C2CCard from './components/C2CCard';
import PaymentCurrencyListModal from './components/PaymentCurrencyListModal';
import RechargeCurrencyListModal from './components/RechargeCurrencyListModal';
import { MaterialSymbolsHeadphones } from '@/assets/icons/mine/MaterialSymbolsHeadphones';

import { Badge } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { SvgSpinnersBarsRotateFade } from '@/assets/icons/comm/SvgSpinnersBarsRotateFade';

export default () => {
  const { t } = useTranslation();
  // 需要充值的币种
  const [currentCoin, setCurrentCoin] = useState<any>();
  // 支付币种
  const [currentPaymentCoin, setCurrentPaymentCoin] = useState<any>({ exchange: 'USD' });

  // 限制金额
  const [amount, setAmount] = useState();
  // 获取可充值的币种列表
  const { run: getRechargeTypeReq, data: RechargeCoinList, loading: loadingRecharge } = useReq(getRechargeTypeApi, {
    manual: true,
    loadingDefault:false,
    onSuccess(data: any) {
      if (data?.length > 0) {
        setCurrentCoin(data.filter((item: any) => item?.alias === 'USDT')?.[0] ?? data?.[0]);
      }
    },
  });
  useMount(() => {
    getRechargeTypeReq({
      rechargeType: RechargeTypeEnum.C2C,
    });
  });
  // 获取法币列表
  const {
    run: getFiatCurrencyBalanceReq,
    data: exchangeList,
    loading: loadingExchange,
  } = useReq(getFiatCurrencyBalanceApi, {
    loadingDefault:false,
    manual: true,
    onSuccess(data: any) {
      if (data?.length > 0) {
        setCurrentPaymentCoin(data.filter((item: any) => item?.alias === 'GBP')?.[0] ?? data?.[0]);
      }
    },
  });
  useMount(() => {
    getFiatCurrencyBalanceReq();
  });
  // 获取C2C列表

  const [params, setParams] = useState({
    pageNo: 1,
    pageSize: 10,
    coinId: currentCoin?.id,
    quoteCoinId: currentPaymentCoin?.id,
    rechargeType: RechargeTypeEnum.C2C,
    amount,
  });
  const { run: getReloadList } = useDebounceFn(
    (params?: any) => {
      setParams(
        {
          pageNo: 1,
          pageSize: 10,
          coinId: currentCoin?.id,
          quoteCoinId: currentPaymentCoin?.id,
          rechargeType: RechargeTypeEnum.C2C,
          amount,
        },
      );
    },
    {
      wait: 50,
    },
  );
  useUpdateEffect(() => {
    getReloadList()
  }, [currentCoin, currentPaymentCoin, amount]);
  const { list, loading, loadMore, error, total, ...resetContractPageProps } =
    usePaginationPage({
      fn: getC2CListApi,
      params,
      setParams: setParams,
      defaultLoading: true,
      manual:true
    });

  return <div>
    <BaseTopNav title={t('C2C交易')}  rightNode={
          loading &&  <SvgSpinnersBarsRotateFade className='mr-4'/>

    }/>
    {/*筛选栏*/}
    <div className="flex justify-between px-4 pb-2 border-b-1 border-borderColor">
      <div className="flex items-center gap-4">
        <RechargeCurrencyListModal
          currencyList={RechargeCoinList}
          changeCurrency={setCurrentCoin}
          currency={currentCoin}
          coinNameField={'name'}
          coinTagField={'icon'}
        >
          <div className="flex items-center gap-1 px-1 py-1">
            <div className="w-[14px] h-[14px] rounded-full bg-backgroundAuxiliaryColor">
              {currentCoin?.icon && <img src={currentCoin?.icon} className="w-full h-full object-cover" alt="" />}
            </div>
            <span className="text-[12px]">{currentCoin?.name}</span>
            <FluentTriangleDown12Filled className="text-auxiliaryTextColor text-[8px] h-[6px]" />
          </div>
        </RechargeCurrencyListModal>

        <AmountFilterModal currentPaymentCoin={currentPaymentCoin} amount={amount} setAmount={setAmount}>
          <div className="flex items-center gap-1 px-1 py-1">
            <span className="text-[12px]">{t('金额')}</span>
            <FluentTriangleDown12Filled className="text-auxiliaryTextColor text-[8px] h-[6px]" />
          </div>
        </AmountFilterModal>
      </div>

      {/*可筛选支付币种*/}
      <PaymentCurrencyListModal exchangeList={exchangeList} currency={currentPaymentCoin}
                                changeCurrency={setCurrentPaymentCoin}>
        <div
          className="flex w-[67px] h-[32px] items-center justify-between gap-1 border-1 border-borderColor rounded-md px-2 py-1">
          <span className="text-[12px]">{currentPaymentCoin?.alias}</span>
          <FluentTriangleDown12Filled className="text-auxiliaryTextColor text-[8px] h-[6px]" />
        </div>
      </PaymentCurrencyListModal>
    </div>
    {/*列表*/}
    <div>
      <PullToRefresh
        loadMore={loadMore}
        total={total}
        list={list}
        error={error}
        loading={loading }
        loadingDisplay={false}
        {...resetContractPageProps}
      >
        {
          list?.map?.((item:any,index:number )=> {
            return <C2CCard key={index} item={item} currentPaymentCoin={currentPaymentCoin} />;
          })
        }
      </PullToRefresh>
    </div>
  </div>;
}