import { useTranslation } from 'react-i18next';
import { Clock } from '@/assets/icons/comm/Clock';
import { useGlobalPrompt } from '@/components/base/basePromptWindow';
import { Button } from '@nextui-org/react';
import { history } from '@umijs/max';
import PageEnum from '@/enums/pageEnum';
import React, { useMemo } from 'react';
import { toJsonData } from '@/utils/socket';
import RenderUtil from '@/utils/RenderUtil';
import { renderI18Template } from '@/utils';

export default ({ item, currentPaymentCoin }: any) => {
  const { t } = useTranslation();
  const { openPrompt } = useGlobalPrompt();
  const findJsonValue = (field: string) => {
    return item?.bankCard
      ? toJsonData(item.bankCard)?.find(
        (it: any) => it.field === field,
      )?.value
      : '';
  };
  const shopInfo = useMemo(() => {
    return {
      ...item,
      businessAvatar: findJsonValue('businessAvatar'), // 商家头像
      merchant: findJsonValue('merchant'), // 商家名称
      volume: findJsonValue('volume'), // 成交量
      orderRate: findJsonValue('orderRate'), // 成单率
      avaCoinReleaseTime: findJsonValue('avaCoinReleaseTime'), // 平均放币时间
      price:item?.rate, // 标价
      quantity: findJsonValue('quantity'), // 数量
    };
  }, [item]);
  return <div className="border-b-1 border-b-borderColor px-4 py-6">
    <div className="flex items-center gap-2">
      <img src={shopInfo?.businessAvatar} className="w-[18px] h-[18px] rounded-full object-cover" alt="" />
      <span>{shopInfo?.merchant}</span>
      <img src={require('@/assets/img/shopLogo.png')} className="w-[14px] h-[14px] object-cover" alt="" />
    </div>


    <div className="flex items-center gap-3 mt-[6px] text-[12px] text-auxiliaryTextColor">
      <div className="flex items-center gap-1">
        <span onClick={() => {
          openPrompt({
            // title: t('提示'),
            content:
              <div
                className="text-center leading-5 text-backContrastColor"
                dangerouslySetInnerHTML={renderI18Template(
                  t(
                    '此商家完成了{{volume}}笔订单，成单率为{{orderRate}}',
                  ),
                  {
                    volume: (<span className="mx-1 text-backContrastColor font-bold">
            {RenderUtil.FormatAmount(shopInfo?.volume, 2)}
          </span>),
                    orderRate: (<span className="mx-1 text-backContrastColor font-bold">
                 {shopInfo?.orderRate +'%'}
                   </span>),
                  },
                )}
              ></div>,
          });
        }} className="border-b-1 border-b-auxiliaryTextColor border-dashed pb-[1px]">{t('成交量')}</span>
        <span>{RenderUtil.FormatAmount(shopInfo?.volume, 2)} ({shopInfo.orderRate}%)</span>
      </div>
      <div className="flex gap-1 items-center">
        <Clock />

        <span onClick={() => {
          openPrompt({
            // title: t('提示'),
            content: <div
              className="text-center leading-5 text-backContrastColor"
              dangerouslySetInnerHTML={renderI18Template(
                t(
                  '该商家平均在{{avaCoinReleaseTime}}小时内放币',
                ),
                {
                  avaCoinReleaseTime:  (<span className="mx-1 font-bold text-backContrastColor">
                 {shopInfo?.avaCoinReleaseTime}
                   </span>)
                },
              )}
            ></div>,
          });
        }}
         className="border-b-1 border-b-auxiliaryTextColor border-dashed pb-[1px]">{shopInfo?.avaCoinReleaseTime}{t('H')}</span>
      </div>
    </div>
    <div className="text-backContrastColor my-3">
      <span className="text-[12px]">$</span>
      <span className="text-[20px] px-1">{RenderUtil.FormatAmount(shopInfo?.price)}</span>
    </div>
    <div className="flex justify-between items-end">
      <div className="flex flex-col gap-2 text-[12px]">
        <div className="flex items-center gap-1">
          <span className="text-auxiliaryTextColor">{t('数量')}</span>
          <span className="text-backContrastColor">{RenderUtil.FormatAmount(shopInfo?.quantity)}{' ' + shopInfo?.coinName}</span>
        </div>
        <div className="flex items-center gap-1">
          <span className="text-auxiliaryTextColor">{t('限额')}</span>
          <span
            className="text-backContrastColor">{RenderUtil.FormatAmount(shopInfo?.minAmount)}-{RenderUtil.FormatAmount(shopInfo?.maxAmount)}{' ' + currentPaymentCoin?.alias}</span>
        </div>
      </div>
      <Button className="bg-[#2DB880] text-[#fff] !h-[32px] !rounded-md" onClick={() => {
        history.push(PageEnum.C2C_BUY, { ...item, payCoinInfo: currentPaymentCoin });
      }}>
        {t('购买')}
      </Button>
    </div>
  </div>;
}