import type { SVGProps } from 'react';

export function Clock(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_12_3450)">
        <path
          d="M6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12ZM8.06998 7.37543L6.51562 6.1867V2.63672C6.51562 2.35195 6.28477 2.12109 6 2.12109C5.71523 2.12109 5.48438 2.35195 5.48438 2.63672V6.69628L7.4435 8.19458C7.66971 8.36757 7.99332 8.32444 8.16632 8.09824C8.33931 7.87204 8.29617 7.54842 8.06998 7.37543Z"
          fill="currentColor" />
      </g>
      <defs>
        <clipPath id="clip0_12_3450">
          <rect width="12" height="12" fill="transparent" />
        </clipPath>
      </defs>
    </svg>

  );
}
