import BaseModal, { IProps } from '@/components/base/baseModal';
import { cn } from '@/utils';
import { Checkbox } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function BasePromptWindow(
  props: Omit<
    IProps & {
      content: string | React.ReactNode;
      checkTitle?: string | React.ReactNode;
      onConfirm?: (isSelected?: any) => void;
      onCancel?: () => void;
      classNames?: any;
      isShowConfirm?: boolean;
      modalProps?: any;
      buttonOption?: any;
      showCancel: boolean;
    },
    'children'
  >,
) {
  const { t } = useTranslation();
  const {
    onClose,
    content,
    onConfirm,
    onCancel,
    checkTitle,
    buttonOption,
    showCancel,
  } = props;

  const [isSelected, setIsSelected] = useState(false);

  const { isPc } = useModel('system');

  return (
    <div>
      <BaseModal
        classNames={{
          title: 'text-[18px] pt-[8px]  font-semibold',
          modal: 'sm:!w-[350px]',
        }}
        placement="center"
        {...props}
      >
        <div className="px-[24px] pb-[20px]">
          <div
            className={cn(`text-auxiliaryTextColor text-left text-[14px]`, {
              'w-[]': isPc,
              ' w-[263px]': !isPc,
            })}
          >
            {content}
          </div>
          {!onCancel && (
            <div className="flex justify-center mt-[24px] gap-[12px]">
              <div
                onClick={() => {
                  onConfirm?.();
                  onClose();
                }}
                className="flex  sm:cursor-pointer justify-center items-center rounded-[8px] text-[16px] bg-primary text-primaryButtonTextColor w-full h-[40px]"
              >
                {buttonOption?.confirmText ?? t('确认')}
              </div>
            </div>
          )}
          {checkTitle && (
            <div className="my-2">
              <Checkbox
                isSelected={isSelected}
                onValueChange={setIsSelected}
                size={'sm'}
                color={'default'}
                defaultSelected
              >
                {checkTitle}
              </Checkbox>
            </div>
          )}
          {onCancel && (
            <div className="flex justify-end mt-[10px] gap-[12px]">
              {showCancel && (
                <div
                  onClick={() => {
                    onCancel?.();
                    onClose();
                  }}
                  className="flex  sm:cursor-pointer justify-center items-center rounded-[8px] text-[14px] bg-backgroundAuxiliaryColor text-backContrastColor px-5 h-[34px]"
                >
                  {buttonOption?.cancelText ?? t('取消')}
                </div>
              )}

              <div
                onClick={() => {
                  onConfirm?.(isSelected);
                  onClose();
                }}
                className="flex  sm:cursor-pointer justify-center items-center rounded-[8px] text-[14px] bg-primary text-primaryButtonTextColor px-5 h-[34px]"
              >
                {buttonOption?.confirmText ?? t('确认')}
              </div>
            </div>
          )}
        </div>
      </BaseModal>
    </div>
  );
}
