import BaseModal from '@/components/base/baseModal';
import { Button, useDisclosure } from '@nextui-org/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NumberInput from '@/components/features/featuresNumberInput';


export default function FeaturesSelectCurrencyDrawer({ children, amount, setAmount,currentPaymentCoin }: any) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [_amount, set_Amount] = useState(amount);
  return (
    <>
      <div onClick={onOpen}>{children}</div>
      <BaseModal isOpen={isOpen} onClose={onClose} title={t('选择币种')}>
        <div className="mt-4  pb-6 px-4 bg-background  ">
          <div
            className="flex px-2 justify-between h-[48px] items-center rounded-md overflow-hidden bg-backgroundAuxiliaryColor pr-2">
            <NumberInput
              className=" overflow-hidden   "
              value={_amount}
              onChange={set_Amount}
              placeholder={t('请输入限制金额')}
              classNames={{
                base: 'rounded-[3px] overflow-hidden h-[40px]',
                inputWrapper:
                  'h-full border-none rounded-[3px]  group-data-[focus=true]:border-backgroundAuxiliaryColor',
              }}
            ></NumberInput>
            <div className="flex items-center gap-1 flex-shrink-0 py-2">
              <div className="h-[18px] mx-2 w-[1px] bg-backContrastColor/10">
              </div>
              <div className="text-base">
                {currentPaymentCoin?.exchange}
              </div>
            </div>
          </div>
          <Button onClick={() => {
            setAmount(_amount);
            onClose();
          }} className="bg-primary mt-4  w-full text-[#fff]  !h-[40px] !rounded-md">
            {t('确定')}
          </Button>
        </div>
      </BaseModal>
    </>
  );
}

