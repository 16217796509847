import { BasilSearchSolid } from '@/assets/icons/comm/BasilSearchSolid';
import { IconParkSolidCorrect } from '@/assets/icons/comm/IconParkSolidCorrect';
import BaseInput from '@/components/base/baseInput';
import { BaseLoadingDisplay } from '@/components/base/baseLoadingDisplay';
import BaseModal from '@/components/base/baseModal';
import { ScrollShadow, useDisclosure } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export default function FeaturesSelectCurrencyDrawer({
                                                       changeCurrency,
                                                       currency,
                                                       children,
                                                       exchangeList,
                                                     }: any) {
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <div onClick={onOpen}>{children}</div>
      <BaseModal isOpen={isOpen} onClose={onClose} title={t('选择币种')}>
        <div className="mt-4   bg-background  ">
          <div className="my-2 px-4">
            <BaseInput
              className="bg-backgroundAuxiliaryColor overflow-hidden h-[40px]"
              startContent={
                <BasilSearchSolid className=" text-iconFontColor text-xl" />
              }
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              placeholder={t('搜索')}
              classNames={{
                base: 'rounded-md overflow-hidden h-[40px]',
                inputWrapper:
                  'h-full border-none rounded-md  group-data-[focus=true]:border-backgroundAuxiliaryColor',
              }}
            ></BaseInput>
          </div>

          <div className="mt-6">
            <ScrollShadow hideScrollBar className="max-h-[40vh]">
              <BaseLoadingDisplay
                loading={false}
                list={
                  Array.isArray(exchangeList) &&
                  (exchangeList?.filter?.((item: any) => {
                    return item?.['alias']
                      ?.toLowerCase()
                      ?.includes(filterValue?.trim?.()?.toLowerCase?.());
                  }) as any)
                }
              >
                <div>
                  {Array.isArray(exchangeList) &&
                    exchangeList
                      ?.filter?.((item: any) => {
                      return item?.['alias']
                        ?.toLowerCase()
                        ?.includes(filterValue?.trim?.()?.toLowerCase?.());
                    })
                      ?.map?.((item: any) => (
                      <div
                        key={item?.['id']}
                        onClick={() => {
                          changeCurrency(item);
                          onClose();
                        }}
                        className="flex items-center px-4 justify-between text-foreground  border-t-[1px] border-backgroundAuxiliaryColor text-[16px]   py-4"
                      >
                        <div className="flex items-center gap-2">
                          <img
                            src={item?.['icon']}
                            alt=""
                            className="w-6 h-6 rounded-full"
                          />
                          <span>{item?.['alias']}</span>
                        </div>
                        {item?.['id'] === (currency as any)?.['id'] && (
                          <IconParkSolidCorrect className="text-primary" />
                        )}
                      </div>
                    ))}
                </div>
              </BaseLoadingDisplay>
            </ScrollShadow>
            <div
              onClick={onClose}
              className={`text-center  border-t-[8px] border-backgroundAuxiliaryColor  text-[16px] text-foreground  py-4 `}
            >
              <span>{t('取消')}</span>
            </div>
          </div>
        </div>
      </BaseModal>
    </>
  );
}
