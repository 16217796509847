import { useDisclosure } from '@nextui-org/react';
import { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BasePromptWindow } from './BasePromptWindow';

// 打开弹窗参数类型
export interface IOpenPromptProps {
  // 标题
  title?: string | React.ReactNode;
  // 复选确认框
  checkTitle?: string | React.ReactNode;
  // 提示内容
  content: string | React.ReactNode;

  showCancel?: boolean;
  // 点击确认的回调事件
  onConfirm?: (isSelected?: any) => void;
  // 关闭按钮的回调事件
  onCancel?: any;
  
    // 类名
    classNames?:any
    // 是否显示确认按钮
    isShowConfirm?: boolean;
    // modal 对应配置项
    modalProps?: any;
    // 按钮配置项
    buttonOption?:any;
}

interface GlobalPromptContextType {
  openPrompt: (props: IOpenPromptProps) => void;
}

export const GlobalPromptWindowContext = createContext<
  GlobalPromptContextType | undefined
>(undefined);

// 自定义hooks,用于直接导出打开方法
export const useGlobalPrompt = () => {
  const { t } = useTranslation();
  const context = useContext(GlobalPromptWindowContext);
  if (!context) {
    throw new Error(t('必须在跟组件包裹BasePromptWindow'));
  }
  return context;
};

// 全局context和弹窗的链接组件
export default ({ children }: { children: React.ReactNode }) => {
  const [content, setContent] = useState<string | React.ReactNode>('');
  const [title, setTitle] = useState<string | React.ReactNode>('');
  const [checkTitle, setCheckTitle] = useState<string | React.ReactNode>('');
  const [onConfirm, setOnConfirm] = useState<() => void>();
  const [onCancel, setOnCancel] = useState<() => void>();
  const [showCancel, setShowCancel] = useState<boolean>(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  
  const [classNames, setClassNames] = useState({});
  const [isShowConfirm, setIsShowConfirm] = useState<boolean>(true);
  const [modalProps, setModalProps] = useState<any>({});
  const [buttonOption, setButtonOption] = useState<any>({
    confirmText: undefined,
    cancelText: undefined,
  });
  // 点击打开弹窗事件
  const openPrompt = ({
    title: _title,
    content: _content,
    onConfirm: _onConfirm,
    onCancel: _onCancel,
    checkTitle: _checkTitle,
    showCancel: _showCancel,
    classNames: _classNames,
    isShowConfirm: _isShowConfirm,
    modalProps: _modalProps,
    buttonOption:_buttonOption
  }: IOpenPromptProps) => {
    setContent(_content);
    setClassNames(_classNames);
    setIsShowConfirm(_isShowConfirm??true); // 默认显示确认按钮 配合!onCancel 来控制是否显示 
    setModalProps(_modalProps??{});
    if (_title) {
      setTitle(_title);
    } else {
      setTitle('');
    }
    if (_onConfirm) {
      setOnConfirm(() => _onConfirm);
    } else {
      setOnConfirm(() => {});
    }
    if (_onCancel) {
      setOnCancel(() => _onCancel);
    } else {
      setOnCancel(() => {});
    }
    setShowCancel(_showCancel ?? true);

    if (_checkTitle) {
      setCheckTitle(_checkTitle);
    } else {
      setCheckTitle('');
    }
    if(_buttonOption){
      setButtonOption(_buttonOption)
    }
    onOpen();
  };

  return (
    <GlobalPromptWindowContext.Provider value={{ openPrompt }}>
      {children}
      <BasePromptWindow
        isOpen={isOpen}
        onConfirm={onConfirm}
        onClose={onClose}
        onCancel={onCancel}
        checkTitle={checkTitle}
        content={content}
        title={title}
        showCancel={showCancel}
        classNames={classNames}
        isShowConfirm={isShowConfirm}
        modalProps={modalProps}
        buttonOption={buttonOption}
      />
    </GlobalPromptWindowContext.Provider>
  );
};
